import { I18n } from "../i18n";
import algoliasearch from "algoliasearch/lite";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import { mapDataStore } from "../data-stores/map-data-store";

const searchClient = algoliasearch(
  window.Algolia.appId,
  window.Algolia.searchKey
);

export const render = (_map, mapUpdater) => {
  autocomplete({
    container: "#search-box",
    placeholder: I18n.t("search.placeholder"),
    getSources({ query, setQuery }) {
      return [
        {
          sourceId: "influence_results",
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: "influences",
                  query,
                  params: {
                    hitsPerPage: 5,
                  },
                },
              ],
            });
          },
          templates: {
            header({ items, html }) {
              return html`<div class="section-header">
                <div class="label">${I18n.t("search.sections.influences")}</div>
                <div class="divider"></div>
              </div>`;
            },
            item({ item, _components, html }) {
              return item[`name_${I18n.currentLocale()}`];
            },
          },
          onSelect({ item }) {
            setQuery(item[`name_${I18n.currentLocale()}`]);
            const startDate = new Date(item.start_date);
            const endDate =
              item.end_date === "" ? new Date() : new Date(item.end_date);

            if (mapUpdater.isInfluenceOnMap(item.slug)) {
              mapUpdater.openPopup(item.slug);
            } else {
              mapDataStore.updateState({
                startDate: startDate,
                endDate: endDate,
                currentInfluenceSlug: item.slug,
              });
            }
          },
        },
        {
          sourceId: "world_event_results",
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: "world_events",
                  query,
                  params: {
                    hitsPerPage: 5,
                  },
                },
              ],
            });
          },
          templates: {
            header({ items, html }) {
              return html`<div class="section-header">
                <div class="label">
                  ${I18n.t("search.sections.world_events")}
                </div>
                <div class="divider"></div>
              </div>`;
            },
            item({ item, _components, html }) {
              const itemName = item[`name_${I18n.currentLocale()}`];
              const startDate = new Date(item.start_date).getFullYear();

              let endDate = item.end_date;

              if (endDate) {
                endDate = ` - ${new Date(endDate).getFullYear()}`;
              }

              return html`<div class="section-item-body">
                ${itemName}<small> ${startDate}${endDate}</small>
              </div>`;
            },
          },
          onSelect({ item }) {
            setQuery(item[`name_${I18n.currentLocale()}`]);
            mapDataStore.updateState({ currentWorldEventSlug: item.slug });
          },
        },
      ];
    },
  });
};
