export class TabPanel {
  constructor({ container = null, elemSelector = null } = {}) {
    this.$container = container;
    this.$panelTabs = this.$container.querySelector(elemSelector);
  }

  renderCollection(collection) {
    this.$panelTabs.insertAdjacentHTML(
      "beforeend",
      `<a data-collectionName="${collection.fetchName()}" class="collection-${collection.fetchId()}">${collection.fetchName()}</a>`
    );
  }

  activateFirstTab() {
    this.$panelTabs.querySelectorAll("a")[0]?.click();
  }

  onTabSelection(callback) {
    const self = this;

    this.$panelTabs.querySelectorAll("a").forEach(($tab) => {
      $tab.addEventListener("click", (event) => {
        self._deactivateAllTabs();
        event.target.classList.add("is-active");

        callback(event, {
          collectionName: event.target.getAttribute("data-collectionName"),
        });
      });
    });
  }

  _deactivateAllTabs() {
    this.$panelTabs.querySelector(".is-active")?.classList?.remove("is-active");
  }
}
