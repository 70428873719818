import { I18n } from "../i18n";

export const fetchWorldEvents = async ({ filters = {} } = {}) => {
  let params = new URLSearchParams(filters);

  const response = await fetch(
    `/api/world_events?locale=${I18n.currentLocale()}&${params}`
  );

  const response_json = await response.json();
  return response_json.data;
};
