export class CollectionItem {
  constructor(
    {
      id = null,
      label = "",
      inputName = "",
      inputValue = "",
      metadata = {},
      footer = "",
      icon = undefined,
      isMultipleChoice = false,
      collection = null,
    } = {},
    $container
  ) {
    this._input = null;
    this._id = id;
    this._label = label;
    this._inputName = inputName;
    this._inputValue = inputValue;
    this._metadata = metadata;
    this._footer = footer;
    this._icon = icon;
    this._isMultipleChoice = isMultipleChoice;
    this._isChecked = false;
    this._collection = collection;

    this._$container = $container;
    this._elemId = `${inputName.replace("[]", "")}-${inputValue}`;
    this._$elem = null;
    this._$input = null;

    this._onSelectedCallback = (_) => {};
  }

  render() {
    let inputClasses = "";
    let inputType = "checkbox";
    let iconHtml = "";
    let footerHtml = "";

    if (this._isMultipleChoice === false) {
      inputClasses = "is-hidden";
      inputType = "radio";
    }

    if (this._icon) {
      iconHtml = `<figure class="image is-32x32">
        <img src="${this._icon}">
      </figure>`;
    }

    if (this._footer) {
      footerHtml = `
        <div class="is-size-7">${this._footer}</div>
      `;
    }

    this._$container.insertAdjacentHTML(
      "beforeend",
      `<div class="option" id="${this._elemId}">
        <label class="panel-block">
          <input class="${inputClasses}" type="${inputType}" name="${this._inputName}" value="${this._inputValue}">
          ${iconHtml}
          ${this._label}
          ${footerHtml}
        </label>
      </div>`
    );

    this._$elem = this._$container.querySelector(`#${this._elemId}`);
    this._$input = this._$elem.querySelector("input");

    this._bindSelectedEvent();
  }

  markChecked(flag) {
    if (this._$input.checked !== flag) {
      this._$input.checked = flag;
    }
    this._isChecked = this._$input.checked;
  }

  displayAsActive() {
    this._$elem.classList.add("is-active");
  }

  displayAsNonActive() {
    this._$elem.classList.remove("is-active");
  }

  displayAsHighlight() {
    this._$elem.classList.remove("is-lowlight");
  }

  displayAsLowlight() {
    this._$elem.classList.add("is-lowlight");
  }

  isSelected() {
    return this._isChecked;
  }

  isHidden() {
    return this._$elem.classList.contains("is-hidden");
  }

  hide() {
    this._$elem.classList.add("is-hidden");
  }

  show() {
    this._$elem.classList.remove("is-hidden");
  }

  onSelected(callback) {
    this._onSelectedCallback = callback;
  }

  fetchMetadata() {
    return this._metadata;
  }

  fetchInputValue() {
    return this._inputValue;
  }

  fetchId() {
    return this._elemId;
  }

  fetchObjectId() {
    return this._id;
  }

  fetchFilterText() {
    return this._label;
  }

  fetchCollection() {
    return this._collection;
  }

  click() {
    this._$input.click();
  }

  // This is necessary for inputs like 'radio' which doesn't change when it receives a click when is already checked.
  // We want it to be forced to be uncheck in this case.
  _forceInputStateChange(changeEvent) {
    if (changeEvent.target.checked === this._isChecked) {
      this._$input.checked = !changeEvent.target.checked;
    }
  }

  _bindSelectedEvent() {
    this._bindInputClick();
  }

  _bindInputClick() {
    const self = this;

    this._$input.addEventListener("click", (event) => {
      self._forceInputStateChange(event);
      self._isChecked = self._$input.checked;

      if (self.isSelected()) {
        self.displayAsActive();
      } else {
        self.displayAsNonActive();
      }

      self._onSelectedCallback(event);
    });
  }
}
