import { Map } from "../map/map";
import { initiateNavbarNavigator } from "../components/navbar-map-navigation";
import { initiateThemeConfigFilter } from "../components/theme-config-filter";
import { initiateFilterByTags } from "../components/filter-tags";
import { initiateFilterByWorldEvents } from "../components/filter-world-events";
import { initiateMapMessage } from "../components/map-message";
import { render as renderSearchBar } from "../components/search-bar";
import { MapUpdater } from "../map/map-updater";
import {
  listenToMapDataStoreUpdates,
  setInitialMapDataStoreState,
} from "../map/map-change-listener";
import { GoogleAnalytics } from "../google_analytics";

const makeMapContainerFit = () => {
  const $navbarMapNavigator = document.getElementById("navbar-map-navigation");
  const $map = document.getElementById("map");

  const newMapHeight =
    document.body.getBoundingClientRect().height -
    $navbarMapNavigator.offsetHeight;

  $map.style.height = `${newMapHeight}px`;
};

export const initiateHome = () => {
  GoogleAnalytics.setPageTitle("Home");
  GoogleAnalytics.setTagId(window.GoogleAnalyticsConfig.tagId);

  const map = new Map({ container: "map" });
  const mapUpdater = new MapUpdater(map);

  listenToMapDataStoreUpdates(mapUpdater);

  makeMapContainerFit();
  initiateNavbarNavigator();
  initiateFilterByTags();
  const worldEventsFilter = initiateFilterByWorldEvents();
  initiateThemeConfigFilter({
    filters: {
      worldEventsFilter: worldEventsFilter,
    },
  });
  initiateMapMessage();
  renderSearchBar(map, mapUpdater);
  setInitialMapDataStoreState();
};
