var $mapMessage = document.getElementById("map-message");
var $title = $mapMessage.querySelector(".message-header p");
var $body = $mapMessage.querySelector(".message-body");
var $buttonHide = $mapMessage.querySelector(".delete");
var _bindCloseButton = function () {
    $buttonHide.addEventListener("click", function (_event) {
        hideMapMessage();
    });
};
var showMapMessage = function () {
    $mapMessage.classList.remove("is-hidden");
};
export var hideMapMessage = function () {
    $mapMessage.classList.add("is-hidden");
};
export var displayMapMessage = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.title, title = _c === void 0 ? "" : _c, _d = _b.body, body = _d === void 0 ? "" : _d;
    $title.innerHTML = title;
    $body.innerHTML = body;
    showMapMessage();
};
export var initiateMapMessage = function () {
    _bindCloseButton();
};
