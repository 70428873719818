export var setUrlParam = function (key, value) {
    if (!value || (value === null || value === void 0 ? void 0 : value.trim()) === "") {
        return removeUrlParam(key);
    }
    var currentUrl = new URL(location.toString());
    currentUrl.searchParams.set(key, value);
    history.pushState({}, "", currentUrl);
};
export var appendUrlParam = function (key, value) {
    var currentUrl = new URL(location.toString());
    currentUrl.searchParams.append(key, value);
    history.pushState({}, "", currentUrl);
};
var removeUrlParam = function (key) {
    var currentUrl = new URL(location.toString());
    currentUrl.searchParams.delete(key);
    history.pushState({}, "", currentUrl);
};
export var fetchUrlParams = function () {
    var currentUrl = new URL(location.toString());
    return currentUrl.searchParams;
};
