import { I18n } from "../i18n";

const _getSourceName = (sourceUrl) => {
  let url = new URL(sourceUrl);
  return url.hostname;
};

const _calculateAge = (dateFrom, yearTo) => {
  let yearFrom = dateFrom.split("-")[0];
  return parseInt(yearTo) - parseInt(yearFrom);
};

const _fetchDatePrecisionMarker = (influenceLocation) => {
  let startDatePrecision = "";
  let endDatePrecision = "";

  if (influenceLocation.start_on_precision === "start_on_lower_precision") {
    startDatePrecision = "~";
  }

  if (influenceLocation.end_on_precision === "end_on_lower_precision") {
    endDatePrecision = "~";
  }

  return {
    startDatePrecision: startDatePrecision,
    endDatePrecision: endDatePrecision,
  };
};

const _buildTitle = (influence) => {
  return `<div class='title is-size-6'><a href="#" class="link"><strong>${influence.name}</strong></a></div>`;
};

const _buildExtract = (influence) => {
  if (influence.extract) {
    return `<div class='extract'>${influence.extract}</div>`;
  }

  return "";
};

const _buildImage = (influence) => {
  if (influence.image_url) {
    return `<div class="column is-4"><img src="${influence.image_url}" /></div>`;
  }

  return "";
};

const _buildAge = (startDate, influenceLocation) => {
  const influenceAge = _calculateAge(influenceLocation.start_on, startDate);

  if (influenceAge >= 0) {
    return `
      <div class='age'>
        <strong>${I18n.t(
          "map_updater.age.label"
        )}:</strong> ${influenceAge}${I18n.t(
      "map_updater.age.suffix"
    )} ${I18n.t("map_updater.age.in")} ${startDate.split("-")[0]}
      </div>`;
  }

  return "";
};

const _buildBornDate = (influenceLocation) => {
  const locationStartOn = new Date(influenceLocation.start_on);
  const datePrecisionMarker =
    _fetchDatePrecisionMarker(influenceLocation).startDatePrecision;

  return `<div class='born_date'>
  <strong>${I18n.t(
    "map_updater.born.label"
  )}:</strong> ${datePrecisionMarker}${locationStartOn.getFullYear()}</div>`;
};

const _buildDeathDate = (influenceLocation) => {
  if (influenceLocation.end_on) {
    const influenceDeathAge = _calculateAge(
      influenceLocation.start_on,
      influenceLocation.end_on.split("-")[0]
    );
    const locationEndOn = new Date(influenceLocation.end_on);
    const datePrecisionMarker =
      _fetchDatePrecisionMarker(influenceLocation).endDatePrecision;

    return `<div class='death_date'><strong>${I18n.t(
      "map_updater.died.label"
    )}:</strong> ${datePrecisionMarker}${locationEndOn.getFullYear()} (~${influenceDeathAge}${I18n.t(
      "map_updater.age.suffix"
    )})</div>`;
  }

  return "";
};

const _buildTags = (tags) => {
  if (!tags) {
    return "";
  }

  let html = `<div class='influence-tag-title'><strong>${I18n.t(
    "map_updater.tags.label"
  )}:</strong></div>`;

  tags.forEach((tag) => {
    html += `
    <div class="media influence-tag">
        <div class="media-left">
          <figure class="image is-32x32">
            <img src="/markers/${
              tag.attributes.marker_image_filename
            }" alt="${I18n.t("filter_tags.tabs." + tag.attributes.kind)} - ${
      tag.attributes.name
    }" title="${I18n.t("filter_tags.tabs." + tag.attributes.kind)} - ${
      tag.attributes.name
    }">
          </figure>
        </div>
        <div class="media-content">
          <p>${tag.attributes.name} <i>(${I18n.t(
      "filter_tags.tabs." + tag.attributes.kind
    )})</i></p>
        </div>
    </div>
    `;
  });

  return html;
};

const _buildSourceUrl = (influence) => {
  if (influence.source_url) {
    return `<div class='source'><strong>${I18n.t(
      "map_updater.source.label"
    )}:</strong> <a href='${
      influence.source_url
    }' target="_blank">${_getSourceName(influence.source_url)}</a></div>`;
  }

  return "";
};

export const buildPopupHtml = ({
  location = null,
  influence = null,
  tags = [],
  startDate = "",
} = {}) => {
  return `<div class='popup' data-influence-slug='${influence.attributes.slug}'>
            ${_buildTitle(influence.attributes)}
            ${_buildExtract(influence.attributes)}
            
            <div class='columns'>
              ${_buildImage(influence.attributes)}

              <div class='column is-8'>
                ${_buildAge(startDate, location.attributes)}
                ${_buildBornDate(location.attributes)}
                ${_buildDeathDate(location.attributes)}
                ${_buildSourceUrl(influence.attributes)}
                ${_buildTags(tags)}
              </div>
            </div>
          </div>`;
};
