import { CollectionItem } from "./collection-item";
import { CollectionMarkAllItem } from "./collection-mark-all-item";

export class Collection {
  constructor(
    {
      name = name,
      itemsConfig = [],
      itemsInputName = "",
      isMultipleChoice = true,
    },
    $container
  ) {
    this._itemsConfig = itemsConfig;
    this._id = name.replace(" ", "");
    this._name = name;
    this._itemsInputName = itemsInputName;
    this._groups = {};
    this._isMultipleChoice = isMultipleChoice;

    this._$container = $container;
    this._$elem = null;

    this._onItemSelected = () => {};
    this._collectionItems = [];
  }

  _renderGroup({ groupName = "" }) {
    const groupClass = groupName.replaceAll(" ", "").toLowerCase();
    const $existingGroup = this._groups[groupName];

    if ($existingGroup) {
      return $existingGroup;
    }

    this._$elem.insertAdjacentHTML(
      "beforeend",
      `<div class="group group-${groupClass}">
        <span class="group-label">${groupName}</span>
      </div>`
    );

    const newGroup = this._$elem.querySelector(`.group-${groupClass}`);
    this._groups[groupName] = newGroup;

    return newGroup;
  }

  _renderItems() {
    const self = this;
    let $itemsContainer;

    this._itemsConfig.forEach((itemConfig) => {
      if (itemConfig.group) {
        $itemsContainer = self._renderGroup({
          groupName: itemConfig.group,
        });
      } else {
        $itemsContainer = self._$elem;
      }

      let collectionItem = new CollectionItem(
        {
          id: itemConfig.objectId,
          label: itemConfig.label,
          inputName: self._itemsInputName,
          inputValue: itemConfig.value,
          metadata: itemConfig.metadata,
          footer: itemConfig.footer,
          icon: itemConfig.icon,
          isMultipleChoice: self._isMultipleChoice,
          collection: self,
        },
        $itemsContainer
      );

      self._collectionItems.push(collectionItem);

      collectionItem.render();
      self._bindCollectionItemsSelection(collectionItem);
    });
  }

  _renderMarkAllItems() {
    this._markAllItem = new CollectionMarkAllItem(this._$elem);
    this._markAllItem.render();
    this._bindMarkAllItemSelection();
  }

  _bindCollectionItemsSelection(collectionItem) {
    const self = this;

    collectionItem.onSelected((event) => {
      self._onItemSelected(event, {
        collectionItem: collectionItem,
        isMarkedAll: false,
      });

      const checkedItemsCount = self._countCheckedItems();

      if (checkedItemsCount < self._collectionItems.length) {
        self.markMarkAllItemAsNonActive();
      } else {
        self._markMarkAllItemAsActive();
      }
    });
  }

  _bindMarkAllItemSelection() {
    const self = this;

    this._markAllItem.onSelected((event) => {
      if (self._markAllItem.isSelected()) {
        self._collectionItems.forEach((collectionItem) => {
          collectionItem.displayAsActive();
          collectionItem.markChecked(true);
        });
      } else {
        self._collectionItems.forEach((collectionItem) => {
          collectionItem.displayAsNonActive();
          collectionItem.markChecked(false);
        });
      }

      self._onItemSelected(event, {
        collectionItem: this._markAllItem,
        isMarkedAll: true,
      });
    });
  }

  _countCheckedItems() {
    let count = 0;

    this._collectionItems.forEach((collectionItem) => {
      if (collectionItem.isSelected()) {
        count += 1;
      }
    });
    return count;
  }

  _markMarkAllItemAsActive() {
    if (this._markAllItem) {
      this._markAllItem.displayAsActive();
      this._markAllItem.markChecked(true);
    }
  }

  markMarkAllItemAsNonActive() {
    if (this._markAllItem) {
      this._markAllItem.displayAsNonActive();
      this._markAllItem.markChecked(false);
    }
  }

  updateItemsConfig(newItemsConfig) {
    this._itemsConfig = newItemsConfig;
    this._collectionItems = [];
  }

  markItemsAsNonActive({ except = null } = {}) {
    this._collectionItems.forEach((collectionItem) => {
      if (collectionItem.fetchId() !== except?.fetchId()) {
        collectionItem.displayAsNonActive();
        collectionItem.markChecked(false);
      }
    });
  }

  markItemAsActiveByInputValue(inputValue) {
    this._collectionItems.forEach((collectionItem) => {
      if (
        collectionItem.fetchInputValue() === inputValue &&
        collectionItem.isSelected() === false
      ) {
        collectionItem.click();
      }
    });
  }

  markSimilarItemAsActive(otherCollectionItem) {
    this._collectionItems.forEach((collectionItem) => {
      if (
        collectionItem !== otherCollectionItem &&
        collectionItem.fetchId() === otherCollectionItem.fetchId()
      ) {
        collectionItem.displayAsActive();
        collectionItem.markChecked(true);
      }
    });
  }

  onItemSelected(callback) {
    this._onItemSelected = callback;
  }

  fetchSelectedItems() {
    return this._collectionItems.filter((collectionItem) => {
      if (collectionItem.isSelected()) {
        return collectionItem;
      }
    });
  }

  fetchAllItems() {
    return this._collectionItems.map((collectionItem) => collectionItem);
  }

  fetchId() {
    return this._id;
  }

  fetchName() {
    return this._name;
  }

  show() {
    this._$elem.classList.remove("is-hidden");
  }

  hide() {
    this._$elem.classList.add("is-hidden");
  }

  showGroups() {
    for (const [key, group] of Object.entries(this._groups)) {
      group.classList.remove("is-hidden");
    }
  }

  hideGroupsWithHiddenItems() {
    for (const [key, group] of Object.entries(this._groups)) {
      const hiddenItems = group.querySelectorAll(".option.is-hidden");
      const allItems = group.querySelectorAll(".option");

      if (hiddenItems.length === allItems.length) {
        group.classList.add("is-hidden");
      }
    }
  }

  render() {
    const $previouslyRenderedElem = this._$container.querySelector(
      `.collection-${this._id}`
    );

    if ($previouslyRenderedElem) {
      $previouslyRenderedElem.innerHTML = "";
      this._groups = [];
    } else {
      this._$container.insertAdjacentHTML(
        "beforeend",
        `<div class="list is-hidden collection-${this._id}"></div>`
      );

      this._$elem = this._$container.querySelector(`.collection-${this._id}`);
    }

    this._renderItems();

    if (this._isMultipleChoice) {
      this._renderMarkAllItems();
    }

    return this._$elem;
  }
}
