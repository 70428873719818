import { Collection } from "./map_dropdown_filter/collection";
import { TabPanel } from "./map_dropdown_filter/tab-panel";
import { FilterLabel } from "./map_dropdown_filter/filter-label";
var MapDropdownFilter = /** @class */ (function () {
    function MapDropdownFilter(_a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.containerId, containerId = _c === void 0 ? null : _c, _d = _b.isMultipleChoice, isMultipleChoice = _d === void 0 ? true : _d, _e = _b.label, label = _e === void 0 ? {
            iconClass: "",
            text: "",
        } : _e;
        this._originalLabel = label;
        this._onSelected = function () { };
        this._onFilterCleared = function () { };
        this._onFilterRendered = function () { };
        this._selectedCollectionItems = [];
        this._isMultipleChoice = isMultipleChoice;
        this._collections = {};
        this.$container = document.getElementById(containerId);
        this.$container.innerHTML = this._buildHtml();
        this.$listsContainer = this.$container.querySelector(".lists");
        this.$form = this.$container.querySelector("form");
        this.tabPanel = new TabPanel({
            container: this.$container,
            elemSelector: ".panel-tabs",
        });
        this._filterLabel = new FilterLabel({
            menuSelector: ".map-filter",
            labelSelector: ".filter-label",
            iconSelector: ".filter-icon",
            buttonSelector: ".button",
            originalLabel: this._originalLabel,
        }, this.$container);
    }
    MapDropdownFilter.prototype._buildHtml = function () {
        return "\n    <div class=\"dropdown is-hoverable map-filter is-hidden\">\n      <div class=\"dropdown-trigger\">\n        <button class=\"button\" aria-haspopup=\"true\" aria-controls=\"dropdown-menu4\">\n          <span class=\"icon is-small\">\n            <i class=\"filter-icon fas ".concat(this._originalLabel.iconClass, "\" aria-hidden=\"true\"></i>\n          </span>\n          <span class=\"filter-label\">").concat(this._originalLabel.text, "</span>\n        </button>\n      </div>\n      <div class=\"dropdown-menu\" id=\"dropdown-menu4\" role=\"menu\">\n        <div class=\"dropdown-content\">\n          <div class=\"dropdown-item\">\n            <p class=\"panel-tabs\">\n            </p>\n            <form action=\"/\" method=\"GET\">\n              <div class=\"lists\">\n              </div>\n            </form>\n          </div>\n        </div>\n      </div>\n    </div>\n    ");
    };
    MapDropdownFilter.prototype._hasActiveFilters = function (currentFormData) {
        return Array.from(currentFormData.keys()).length > 0;
    };
    MapDropdownFilter.prototype._bindFormSubmit = function () {
        var _this = this;
        var self = this;
        this.$form.addEventListener("submit", function (event) {
            var _a;
            event.preventDefault();
            var currentFormData = new FormData(event.currentTarget);
            self._filterLabel.processMenuChange({
                collections: self._collections,
                currentFormData: currentFormData,
            });
            if (self._hasActiveFilters(currentFormData)) {
                var metadata = (_a = _this._selectedCollectionItems[0]) === null || _a === void 0 ? void 0 : _a.fetchMetadata();
                self._onSelected(event, currentFormData, metadata);
            }
            else {
                self._resetFilter();
                self._onFilterCleared();
            }
        });
    };
    MapDropdownFilter.prototype._bindTabSelection = function () {
        var self = this;
        this.tabPanel.onTabSelection(function (_event, metadata) {
            self._hideAllCollections();
            self._collections[metadata.collectionName].show();
        });
    };
    MapDropdownFilter.prototype._markAllCollectionsAsNonActive = function (_a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.exceptCollectionItem, exceptCollectionItem = _c === void 0 ? null : _c;
        for (var collectionId in this._collections) {
            this._collections[collectionId].markItemsAsNonActive({
                except: exceptCollectionItem,
            });
            this._collections[collectionId].markMarkAllItemAsNonActive();
        }
    };
    MapDropdownFilter.prototype._markSimilarCollectionItemsAsActive = function (collectionItem) {
        for (var collectionId in this._collections) {
            this._collections[collectionId].markSimilarItemAsActive(collectionItem);
        }
    };
    MapDropdownFilter.prototype._hideAllCollections = function () {
        for (var collectionName in this._collections) {
            this._collections[collectionName].hide();
        }
    };
    MapDropdownFilter.prototype._bindCollectionItemSelected = function (collection) {
        var self = this;
        collection.onItemSelected(function (_event, data) {
            if (data.isMarkedAll === false) {
                if (self._isMultipleChoice === false) {
                    self._markAllCollectionsAsNonActive({
                        exceptCollectionItem: data.collectionItem,
                    });
                }
                self._selectedCollectionItems = [data.collectionItem];
                // self._markSimilarCollectionItemsAsActive(data.collectionItem);
            }
            self.$form.requestSubmit();
        });
    };
    MapDropdownFilter.prototype._bindMenuClearRequest = function () {
        var self = this;
        this._filterLabel.onFilterClearRequested(function (_event) {
            self._resetFilter();
        });
    };
    MapDropdownFilter.prototype._resetFilter = function () {
        this._selectedCollectionItems = [];
        this._markAllCollectionsAsNonActive();
        this.$form.requestSubmit();
    };
    MapDropdownFilter.prototype._renderCollection = function (collection) {
        if (!this._collections[collection.fetchName()]) {
            this._collections[collection.fetchName()] = collection;
        }
        collection.render();
        this._bindCollectionItemSelected(collection);
    };
    MapDropdownFilter.prototype.addCollection = function (_a) {
        var name = _a.name, list = _a.list, formParam = _a.formParam;
        var collection = new Collection({
            name: name,
            itemsConfig: list,
            itemsInputName: formParam,
            isMultipleChoice: this._isMultipleChoice,
        }, this.$listsContainer);
        this._renderCollection(collection);
        this.tabPanel.renderCollection(collection);
        return collection;
    };
    MapDropdownFilter.prototype.updateCollection = function (_a) {
        var name = _a.name, list = _a.list;
        var collection = this._collections[name];
        if (collection) {
            collection.updateItemsConfig(list);
            this._renderCollection(collection);
        }
    };
    MapDropdownFilter.prototype.markItemAsActiveByInputValue = function (inputValue) {
        Object.values(this._collections).forEach(function (collection) {
            collection.markItemAsActiveByInputValue(inputValue);
        });
    };
    MapDropdownFilter.prototype.fetchAllCollectionItems = function () {
        return Object.values(this._collections)
            .map(function (collection) { return collection.fetchAllItems(); })
            .flat();
    };
    MapDropdownFilter.prototype.onSelected = function (fn) {
        this._onSelected = fn;
    };
    MapDropdownFilter.prototype.onFilterCleared = function (fn) {
        this._onFilterCleared = fn;
    };
    MapDropdownFilter.prototype.onFilterRendered = function (fn) {
        this._onFilterRendered = fn;
    };
    MapDropdownFilter.prototype.render = function () {
        this._bindTabSelection();
        this._bindFormSubmit();
        this.tabPanel.activateFirstTab();
        this._bindMenuClearRequest();
        this.$container.querySelector(".map-filter").classList.remove("is-hidden");
        this._onFilterRendered();
    };
    return MapDropdownFilter;
}());
export { MapDropdownFilter };
