import { I18n } from "../i18n";

export const fetchInfluenceLocations = async ({ filters = {} } = {}) => {
  let params = new URLSearchParams(filters);

  if (filters["tags[]"] && filters["tags[]"].length > 0) {
    params.delete("tags[]");
    filters["tags[]"].forEach((tag) => {
      params.append("tags[]", tag);
    });
  }

  const response = await fetch(
    `/api/influence_locations?locale=${I18n.currentLocale()}&${params}`
  );

  const response_json = await response.json();
  return response_json.data;
};

export const fetchTags = async () => {
  const response = await fetch(`/api/tags?locale=${I18n.currentLocale()}`);

  const response_json = await response.json();
  return response_json.data;
};
