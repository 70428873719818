import { I18n } from "../i18n";
import { fetchWorldEvents } from "../api/world-events";
import { MapDropdownFilter } from "./map-dropdown-filter";
import { hideMapMessage, displayMapMessage } from "./map-message";
import { mapDataStore } from "../data-stores/map-data-store";
import { autorun } from "mobx";
var filter;
var staticCollections = [];
var _fetchDatePrecisionMarker = function (worldEvent) {
    var startDatePrecision = "";
    var endDatePrecision = "";
    if (worldEvent.start_date_precision === "start_date_lower_precision") {
        startDatePrecision = "~";
    }
    if (worldEvent.end_date_precision === "end_date_lower_precision") {
        endDatePrecision = "~";
    }
    return {
        startDatePrecision: startDatePrecision,
        endDatePrecision: endDatePrecision,
    };
};
var _buildFilter = function () {
    var filter = new MapDropdownFilter({
        containerId: "filter-world-events",
        isMultipleChoice: false,
        label: {
            iconClass: "fa-regular fa-calendar-days",
            text: I18n.t("filter_world_events.label"),
        },
    });
    return filter;
};
var _bindFilterCleared = function (filter) {
    filter.onFilterCleared(function () {
        mapDataStore.updateState({ currentWorldEventSlug: "" });
        hideMapMessage();
    });
};
var _bindItemSelection = function (filter) {
    filter.onSelected(function (event, data, metadata) {
        var endDate = new Date();
        if (metadata.end_on) {
            endDate = new Date(metadata.end_on);
        }
        mapDataStore.updateState({
            currentWorldEventSlug: data.getAll("world_events[]")[0],
            startDate: new Date(metadata.start_on),
            endDate: endDate,
        });
        displayMapMessage({ title: metadata.name, body: metadata.extract });
    });
};
var _groupWorldEvents = function (worldEvents) {
    var grouped = {};
    worldEvents.forEach(function (worldEvent) {
        var worldEventYear = new Date(worldEvent.attributes.start_on).getFullYear();
        var yearGroup = Math.floor(worldEventYear / 100);
        if (!grouped[yearGroup]) {
            grouped[yearGroup] = [];
        }
        grouped[yearGroup].push(worldEvent);
    });
    return grouped;
};
var _buildFilterCollectionList = function (worldEvents) {
    var worldEventsGrouped = _groupWorldEvents(worldEvents);
    var sortedGroups = Object.keys(worldEventsGrouped).sort();
    var collectionList = [];
    sortedGroups.forEach(function (yearGroup) {
        worldEventsGrouped[yearGroup].forEach(function (worldEvent) {
            var startOn = new Date(worldEvent.attributes.start_on);
            var startOnPrecisionMarker = _fetchDatePrecisionMarker(worldEvent.attributes).startDatePrecision;
            var endOnLabel;
            var endOnPrecisionMarker;
            if (worldEvent.attributes.end_on) {
                endOnLabel = "- ";
                endOnLabel += new Date(worldEvent.attributes.end_on)
                    .getFullYear()
                    .toString();
                endOnPrecisionMarker = _fetchDatePrecisionMarker(worldEvent.attributes).endDatePrecision;
            }
            else {
                endOnLabel = "- <i>".concat(I18n.t("filter_world_events.happening_label"), "</i>");
                endOnPrecisionMarker = "";
            }
            collectionList.push({
                objectId: worldEvent.id,
                value: worldEvent.attributes.slug,
                label: worldEvent.attributes.name,
                footer: "".concat(startOnPrecisionMarker).concat(startOn.getFullYear(), " ").concat(endOnPrecisionMarker).concat(endOnLabel),
                group: "".concat(yearGroup, "00 - ").concat(yearGroup, "99"),
                metadata: {
                    start_on: worldEvent.attributes.start_on,
                    end_on: worldEvent.attributes.end_on,
                    extract: worldEvent.attributes.extract,
                    name: worldEvent.attributes.name,
                },
            });
        });
    });
    return collectionList;
};
var _worldEventsByKind = function (worldEvents) {
    var worldEventsByKind = {};
    worldEvents.forEach(function (worldEvent) {
        if (worldEventsByKind[worldEvent.attributes.kind] === undefined) {
            worldEventsByKind[worldEvent.attributes.kind] = [];
        }
        worldEventsByKind[worldEvent.attributes.kind].push(worldEvent);
    });
    return worldEventsByKind;
};
var _updateHappeningTab = function (startOnFilter, endOnFilter, selectedWorldEvent) {
    fetchWorldEvents({
        filters: { start_on: startOnFilter, end_on: endOnFilter },
    }).then(function (worldEvents) {
        var worldEventsByKind = _worldEventsByKind(worldEvents);
        var newHappeningEvents = [];
        Object.keys(worldEventsByKind)
            .sort()
            .forEach(function (kindName) {
            var worldEvents = worldEventsByKind[kindName].sort();
            worldEvents.forEach(function (worldEvent) {
                if (worldEvent.attributes.slug !== selectedWorldEvent) {
                    var startOn = new Date(worldEvent.attributes.start_on);
                    var startOnPrecisionMarker = _fetchDatePrecisionMarker(worldEvent.attributes).startDatePrecision;
                    var endOnLabel = void 0;
                    var endOnPrecisionMarker = void 0;
                    if (worldEvent.attributes.end_on) {
                        endOnLabel = "- ";
                        endOnLabel += new Date(worldEvent.attributes.end_on)
                            .getFullYear()
                            .toString();
                        endOnPrecisionMarker = _fetchDatePrecisionMarker(worldEvent.attributes).endDatePrecision;
                    }
                    else {
                        endOnLabel = "- <i>".concat(I18n.t("filter_world_events.happening_label"), "</i>");
                        endOnPrecisionMarker = "";
                    }
                    newHappeningEvents.push({
                        id: worldEvent.id,
                        value: worldEvent.attributes.slug,
                        label: worldEvent.attributes.name,
                        footer: "".concat(startOnPrecisionMarker).concat(startOn.getFullYear(), " ").concat(endOnPrecisionMarker).concat(endOnLabel),
                        group: I18n.t("filter_world_events.tabs.".concat(kindName.toLowerCase())),
                        metadata: {
                            start_on: worldEvent.attributes.start_on,
                            end_on: worldEvent.attributes.end_on,
                            extract: worldEvent.attributes.extract,
                            name: worldEvent.attributes.name,
                        },
                    });
                }
            });
        });
        filter.updateCollection({
            name: I18n.t("filter_world_events.tabs.happening"),
            list: newHappeningEvents,
        });
    });
};
var _listenToMapUpdates = function () {
    autorun(function () {
        var startOnFilter = mapDataStore.currentISODates.startDate;
        var endOnFilter = mapDataStore.currentISODates.endDate;
        var selectedWorldEvent = mapDataStore.currentWorldEventSlug;
        _updateHappeningTab(startOnFilter, endOnFilter, selectedWorldEvent);
        if (selectedWorldEvent) {
            staticCollections.forEach(function (collection) {
                collection.markItemAsActiveByInputValue(selectedWorldEvent);
            });
        }
    });
};
export var initiateFilterByWorldEvents = function () {
    filter = _buildFilter();
    fetchWorldEvents().then(function (worldEvents) {
        _bindItemSelection(filter);
        _bindFilterCleared(filter);
        filter.addCollection({
            name: I18n.t("filter_world_events.tabs.happening"),
            formParam: "world_events[]",
        });
        var worldEventsByKind = _worldEventsByKind(worldEvents);
        Object.keys(worldEventsByKind)
            .sort()
            .forEach(function (kindName) {
            var worldEvents = worldEventsByKind[kindName].sort();
            var collectionList = _buildFilterCollectionList(worldEvents);
            var collection = filter.addCollection({
                name: I18n.t("filter_world_events.tabs.".concat(kindName.toLowerCase())),
                formParam: "world_events[]",
                list: collectionList,
            });
            staticCollections.push(collection);
        });
        filter.render();
        _listenToMapUpdates();
    });
    return filter;
};
