/* This file depends on "app/views/layouts/application.html.erb" to implement window.I18n
object on its <head> before application.js is loaded, with:

<script type="text/javascript">
  window.I18n = { currentLocale: <%= I18n.t(:js_components).to_json.html_safe %> }
</script>

All the translations are under js_components, with no need to specify that on the required key.
*/
export var I18n = {
    currentLocale: function () {
        return window.I18n.currentLocale.toLowerCase();
    },
    // Usage example: I18n.t("filter_world_events.label")
    t: function (key) {
        var keys = key.split(".");
        var translation = window.I18n.translations;
        keys.forEach(function (key) {
            translation = translation[key] || "";
        });
        return translation;
    },
};
