import { I18n } from "../i18n";
import { fetchTags } from "../api/influence";
import { MapDropdownFilter } from "./map-dropdown-filter";
import { mapDataStore } from "../data-stores/map-data-store";
import { autorun } from "mobx";
var filter;
var _buildFilter = function () {
    var filter = new MapDropdownFilter({
        containerId: "filter-tags",
        label: {
            iconClass: "fa-sharp fa-solid fa-user-tag",
            text: I18n.t("filter_tags.label"),
        },
    });
    filter.onSelected(function (_event, data) {
        mapDataStore.updateState({ tags: data.getAll("tags[]") });
    });
    filter.onFilterCleared(function () {
        mapDataStore.updateState({ tags: [] });
    });
    return filter;
};
var _tagsByKind = function (tags) {
    var tagsByKind = {};
    tags.forEach(function (tag) {
        if (tagsByKind[tag.attributes.kind] === undefined) {
            tagsByKind[tag.attributes.kind] = [];
        }
        tagsByKind[tag.attributes.kind].push(tag);
    });
    return tagsByKind;
};
var _listenToMapUpdates = function () {
    autorun(function () {
        mapDataStore.tags.forEach(function (tagSlug) {
            filter.markItemAsActiveByInputValue(tagSlug);
        });
    });
};
export var initiateFilterByTags = function () {
    fetchTags().then(function (tags) {
        filter = _buildFilter();
        var tagsByKind = _tagsByKind(tags);
        Object.keys(tagsByKind)
            .sort()
            .forEach(function (kindName) {
            var tags = tagsByKind[kindName].sort();
            filter.addCollection({
                name: I18n.t("filter_tags.tabs.".concat(kindName.toLowerCase())),
                formParam: "tags[]",
                list: tags.map(function (tag) { return ({
                    value: tag.attributes.slug,
                    label: tag.attributes.name,
                    icon: "/markers/".concat(tag.attributes.marker_image_filename),
                }); }),
            });
        });
        filter.render();
        _listenToMapUpdates();
    });
};
