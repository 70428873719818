import { CollectionItem } from "./collection-item";

export class FilterLabel {
  constructor(
    {
      menuSelector = null,
      labelSelector = null,
      iconSelector = null,
      buttonSelector = null,
      originalLabel = {
        iconClass: "",
        text: "",
      },
    } = {},
    $container
  ) {
    this._currentFilterTexts = [];
    this._originalLabel = originalLabel;

    this._onFilterClearRequested = () => {};

    this._$container = $container;
    this._$menu = this._$container.querySelector(menuSelector);
    this._$menuButton = this._$menu.querySelector(buttonSelector);
    this._$menuFilterLabel = this._$menu.querySelector(labelSelector);
    this._$menuFilterIcon = this._$menu.querySelector(iconSelector);

    this._bindMenuClick();
  }

  _isMenuFiltered() {
    return this._$menu.classList.contains("is-filtered");
  }

  _markAsFiltered() {
    if (this._isMenuFiltered()) {
      return;
    }

    this._$menu.classList.add("is-filtered");
    this._originalLabel.iconClass.split(" ").forEach((cssClass) => {
      this._$menuFilterIcon.classList.remove(cssClass);
    });
    this._$menuFilterIcon.classList.add("fa-solid");
    this._$menuFilterIcon.classList.add("fa-circle-xmark");
  }

  _markAsNonFiltered() {
    if (!this._isMenuFiltered()) {
      return;
    }

    this._$menu.classList.remove("is-filtered");
    this._originalLabel.iconClass.split(" ").forEach((cssClass) => {
      this._$menuFilterIcon.classList.add(cssClass);
    });
    this._$menuFilterIcon.classList.remove("fa-solid");
    this._$menuFilterIcon.classList.remove("fa-circle-xmark");
  }

  _bindMenuClick() {
    const self = this;

    this._$menuButton.addEventListener("click", (event) => {
      if (self._isMenuFiltered()) {
        self._onFilterClearRequested(event);
      }
    });
  }

  _fetchFilteredCollectionItems(collections) {
    let filteredCollectionItems = [];

    for (let i in collections) {
      let collection = collections[i];
      filteredCollectionItems.push(collection.fetchSelectedItems());
    }

    return filteredCollectionItems.flat();
  }

  _refreshMenuFiltersText(filteredCollectionItems) {
    this._currentFilterTexts = filteredCollectionItems
      .slice(0, 3)
      .map((collectionItem) => {
        return collectionItem.fetchFilterText();
      });
  }

  _updateLabelText(text) {
    this._$menuFilterLabel.innerHTML = text;
  }

  _hasActiveFilters(currentFormData) {
    return Array.from(currentFormData.keys()).length > 0;
  }

  onFilterClearRequested(callback) {
    this._onFilterClearRequested = callback;
  }

  processMenuChange({ collections = {}, currentFormData = {} } = {}) {
    if (this._hasActiveFilters(currentFormData)) {
      this._markAsFiltered();
      this._refreshMenuFiltersText(
        this._fetchFilteredCollectionItems(collections)
      );
      this._updateLabelText(this._currentFilterTexts.join(", "));
    } else {
      this._markAsNonFiltered();
      this._updateLabelText(this._originalLabel.text);
    }
  }
}
