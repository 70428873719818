import { makeObservable, action, computed, observable } from "mobx";
var MapDataStore = /** @class */ (function () {
    function MapDataStore() {
        this._startDate;
        this._endDate;
        this._startDateISO = "";
        this._endDateISO = "";
        this.tags = [];
        this.currentWorldEventSlug = "";
        this.currentInfluenceSlug = "";
        makeObservable(this, {
            updateState: action,
            currentISODates: computed,
            currentYears: computed,
            _startDateISO: observable,
            _endDateISO: observable,
            tags: observable,
            currentWorldEventSlug: observable,
            currentInfluenceSlug: observable,
        });
    }
    MapDataStore.prototype.updateState = function (_a) {
        var _b, _c, _d, _e;
        var tags = _a.tags, currentWorldEventSlug = _a.currentWorldEventSlug, currentInfluenceSlug = _a.currentInfluenceSlug, startDate = _a.startDate, endDate = _a.endDate;
        this.tags = tags !== null && tags !== void 0 ? tags : this.tags;
        this.currentWorldEventSlug =
            currentWorldEventSlug !== null && currentWorldEventSlug !== void 0 ? currentWorldEventSlug : this.currentWorldEventSlug;
        this.currentInfluenceSlug =
            currentInfluenceSlug !== null && currentInfluenceSlug !== void 0 ? currentInfluenceSlug : this.currentInfluenceSlug;
        this._startDate = startDate !== null && startDate !== void 0 ? startDate : this._startDate;
        this._endDate = endDate !== null && endDate !== void 0 ? endDate : this._endDate;
        this._startDateISO = (_c = (_b = this._startDate) === null || _b === void 0 ? void 0 : _b.toISOString().split("T")[0]) !== null && _c !== void 0 ? _c : "";
        this._endDateISO = (_e = (_d = this._endDate) === null || _d === void 0 ? void 0 : _d.toISOString().split("T")[0]) !== null && _e !== void 0 ? _e : "";
    };
    MapDataStore.prototype.currentDates = function () {
        return {
            startDate: this._startDate,
            endDate: this._endDate,
        };
    };
    Object.defineProperty(MapDataStore.prototype, "currentISODates", {
        get: function () {
            return {
                startDate: this._startDateISO,
                endDate: this._endDateISO,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MapDataStore.prototype, "currentYears", {
        get: function () {
            var _a;
            return {
                startYear: this._startDateISO.split("-")[0],
                endYear: (_a = this._endDateISO) === null || _a === void 0 ? void 0 : _a.split("-")[0],
            };
        },
        enumerable: false,
        configurable: true
    });
    return MapDataStore;
}());
export var mapDataStore = new MapDataStore();
