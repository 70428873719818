var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* Encapsulates gtag functions from Google Analytics */
export var GoogleAnalytics = {
    setTagId: function (tagId) {
        gtag("config", tagId);
    },
    sendMapFilteredDates: function (dates) {
        gtag("event", "map_filtered", __assign({ app_name: "Pinfluence" }, dates));
    },
    sendWorldEventsSelected: function (worldEvents) {
        worldEvents.forEach(function (worldEvent) {
            if (worldEvent) {
                gtag("event", "world_event_selected", {
                    app_name: "Pinfluence",
                    world_event: worldEvent,
                });
            }
        });
    },
    sendTagsSelected: function (tags) {
        tags.forEach(function (tag) {
            if (tag) {
                gtag("event", "tag_selected", {
                    app_name: "Pinfluence",
                    tag: tag,
                });
            }
        });
    },
    sendInfluencesSelected: function (influences) {
        influences.forEach(function (influence) {
            if (influence) {
                gtag("event", "influence_selected", {
                    app_name: "Pinfluence",
                    influence: influence,
                });
            }
        });
    },
    setPageTitle: function (title) {
        gtag("set", {
            page_title: title,
        });
    },
};
