export class Map {
  constructor({
    container = "map",
    autoStart = true,
    center = [0, 0],
    zoom = 3,
  } = {}) {
    this.map = undefined;
    this.container = container;
    this.center = center;
    this.zoom = zoom;

    if (autoStart === true) {
      this.start();
    }
  }

  start() {
    const self = this;
    this.map = L.map(this.container).setView(this.center, this.zoom);

    this.map.on("popupopen", function (e) {
      const popupPixelCoords = self.map.project(e.popup.getLatLng());
      const popupHeight = e.popup.getElement().clientHeight;
      popupPixelCoords.y -= popupHeight / 2; // move the popup vertical axis location down (distance: half of popup height)
      self.map.panTo(self.map.unproject(popupPixelCoords), { animate: true }); // pan to calculated location
    });

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      minZoom: 3,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(this.map);
  }

  buildPopup({
    content = "",
    maxWidth = 500,
    customClassName = "",
    minWidth = 300,
  } = {}) {
    return L.popup({
      content: content,
      maxWidth: maxWidth,
      minWidth: minWidth,
      className: customClassName,
    });
  }

  addMarker({
    lat = undefined,
    lng = undefined,
    popup = undefined,
    iconFilename = undefined,
    opacity = 1,
    onOpen = () => {},
    onClose = () => {},
  } = {}) {
    const self = this;

    let options = {
      opacity: opacity,
    };

    if (iconFilename) {
      options["icon"] = L.icon({
        iconUrl: `/markers/${iconFilename}`,
        iconSize: [38, 38],
        popupAnchor: [0, -20],
      });
    }

    const marker = L.marker([lat, lng], options)
      .addTo(this.map)
      .bindPopup(popup);

    marker.on("popupopen", (event) => {
      onOpen(event);
    });

    marker.on("popupclose", (event) => {
      onClose(event);
    });

    return marker;
  }

  removeMarkers(markers) {
    const self = this;

    markers.forEach((markerData) => {
      self.map.removeLayer(markerData.marker);
    });
  }

  removeMarker(marker) {
    this.map.removeLayer(marker);
  }

  fetchCurrentZoom() {
    return this.map.zoom;
  }

  moveCenterTo(coords) {
    const latLng = L.latLng(coords.split(",")[0], coords.split(",")[1]);
    this.map.setView(latLng, this.fetchCurrentZoom());
  }
}
