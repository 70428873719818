var $navbarMapNavigator = document.getElementById("navbar-map-navigation");
var $navbarForm = $navbarMapNavigator.querySelector("form");
var $startOnField = $navbarMapNavigator.querySelector("input[name='start_on']");
var $endOnField = $navbarMapNavigator.querySelector("input[name='end_on']");
var $endOnInvalidIcon = $navbarMapNavigator.querySelector(".end_on-invalid-icon");
var $endOnContainer = $navbarMapNavigator.querySelector(".container-end_on");
import { autorun } from "mobx";
import { mapDataStore } from "../data-stores/map-data-store";
var _submitForm = function (event) {
    var data = new FormData(event.currentTarget);
    var startDate = new Date("".concat(data.get("start_on"), "-01-01"));
    var endDate = new Date("".concat(data.get("start_on"), "-12-31"));
    if (data.get("end_on") !== "") {
        endDate = new Date("".concat(data.get("end_on"), "-12-31"));
    }
    if (startDate <= endDate) {
        $endOnContainer.classList.remove("has-icons-right");
        $endOnField.classList.remove("is-danger");
        $endOnInvalidIcon.classList.add("is-hidden");
        mapDataStore.updateState({ startDate: startDate, endDate: endDate });
    }
    else {
        $endOnContainer.classList.add("has-icons-right");
        $endOnField.classList.add("is-danger");
        $endOnInvalidIcon.classList.remove("is-hidden");
    }
};
export var initiateNavbarNavigator = function () {
    $navbarForm.addEventListener("submit", function (event) {
        event.preventDefault();
        _submitForm(event);
    });
    autorun(function () {
        $startOnField.value = mapDataStore.currentYears.startYear;
        $endOnField.value = mapDataStore.currentYears.endYear;
    });
};
