import { I18n } from "../i18n";
import { fetchTheme } from "../api/themes";
import { MapDropdownFilter } from "./map-dropdown-filter";
import { mapDataStore } from "../data-stores/map-data-store";
var filterContainerId = "theme-config-filter";
var filterContainer = document.getElementById(filterContainerId);
var themeWorldEvents = [];
var themeWorldEventIds = [];
var worldEventsCollectionItems = [];
var _fetchThemeStartEndDates = function () {
    var themeStartDate = new Date();
    var themeEndDate = null;
    themeWorldEvents.forEach(function (worldEvent) {
        var eventStartDate = new Date(worldEvent.attributes.start_on);
        var eventEndDate = new Date(worldEvent.attributes.end_on);
        if (themeStartDate.getTime() > eventStartDate.getTime()) {
            themeStartDate = eventStartDate;
        }
        if (themeEndDate === null ||
            themeEndDate.getTime() < eventEndDate.getTime()) {
            themeEndDate = eventEndDate;
        }
    });
    return [themeStartDate, themeEndDate];
};
var _fetchActiveThemeUUID = function () {
    return filterContainer.getAttribute("data-theme_uuid");
};
var _applyThemeInitialDates = function () {
    var _a = _fetchThemeStartEndDates(), startDate = _a[0], endDate = _a[1];
    mapDataStore.updateState({
        startDate: startDate,
        endDate: endDate,
    });
};
var _displayAllEventsWithHighlight = function () {
    worldEventsCollectionItems.forEach(function (collectionItem) {
        collectionItem.show();
        collectionItem.fetchCollection().showGroups();
        if (themeWorldEventIds.includes(collectionItem.fetchObjectId())) {
            collectionItem.displayAsHighlight();
        }
        else {
            collectionItem.displayAsLowlight();
        }
    });
};
var _displayOnlyThemeEvents = function () {
    if (themeWorldEventIds.length <= 0) {
        return;
    }
    worldEventsCollectionItems.forEach(function (collectionItem) {
        if (!themeWorldEventIds.includes(collectionItem.fetchObjectId())) {
            collectionItem.hide();
            collectionItem.fetchCollection().hideGroupsWithHiddenItems();
        }
    });
};
var _buildFilter = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.name, name = _c === void 0 ? "" : _c;
    var filter = new MapDropdownFilter({
        containerId: filterContainerId,
        isMultipleChoice: false,
        label: {
            iconClass: "fa-sharp fa-solid fa-arrow-right",
            text: "".concat(I18n.t("theme_config_filter.label"), ": <strong>").concat(name, "</strong>"),
        },
    });
    filter.onSelected(function (_event, form) {
        var worldEventsVisibility = form.get("worldEventsVisibility");
        if (worldEventsVisibility === "all-with-highlight") {
            _displayAllEventsWithHighlight();
        }
    });
    filter.onFilterCleared(function (_event) {
        _displayOnlyThemeEvents();
    });
    return filter;
};
export var initiateThemeConfigFilter = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.filters, filters = _c === void 0 ? { worldEventsFilter: null } : _c;
    var activeThemeUUID = _fetchActiveThemeUUID();
    if (!activeThemeUUID) {
        return;
    }
    filters.worldEventsFilter.onFilterRendered(function () {
        worldEventsCollectionItems =
            filters.worldEventsFilter.fetchAllCollectionItems();
        _displayOnlyThemeEvents();
    });
    fetchTheme({ uuid: activeThemeUUID }).then(function (theme) {
        themeWorldEvents = theme.attributes.world_events.data;
        themeWorldEventIds = themeWorldEvents.map(function (theme) { return theme.id; });
        var filter = _buildFilter({ name: theme.attributes.name });
        filter.addCollection({
            name: I18n.t("theme_config_filter.world_events"),
            formParam: "worldEventsVisibility",
            list: [
                {
                    value: "all-with-highlight",
                    label: I18n.t("theme_config_filter.all_with_highlight"),
                },
            ],
        });
        _displayOnlyThemeEvents();
        filter.render();
        _applyThemeInitialDates();
    });
};
