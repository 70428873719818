import { autorun } from "mobx";
import { mapDataStore } from "../data-stores/map-data-store";
import { appendUrlParam, fetchUrlParams, setUrlParam } from "../current_url";
import { GoogleAnalytics } from "../google_analytics";
export var listenToMapDataStoreUpdates = function (mapUpdater) {
    var firstTimeListeningToUpdates = true;
    var firstTimeListeningToCurrentInfluenceUpdates = true;
    autorun(function () {
        mapUpdater.assignFilters({
            start_on: mapDataStore.currentISODates.startDate,
            end_on: mapDataStore.currentISODates.endDate,
            "tags[]": mapDataStore.tags,
            "world_events[]": [mapDataStore.currentWorldEventSlug],
        });
        if (firstTimeListeningToUpdates) {
            firstTimeListeningToUpdates = false;
        }
        else {
            setUrlParam("start_date", mapDataStore.currentISODates.startDate);
            setUrlParam("end_date", mapDataStore.currentISODates.endDate);
            setUrlParam("current_world_event", mapDataStore.currentWorldEventSlug);
            setUrlParam("tags[]", "");
            mapDataStore.tags.forEach(function (tagSlug) {
                appendUrlParam("tags[]", tagSlug);
            });
        }
        mapUpdater.filterMap();
        GoogleAnalytics.sendMapFilteredDates({
            start_date: mapDataStore.currentISODates.startDate,
            end_date: mapDataStore.currentISODates.endDate,
        });
        GoogleAnalytics.sendWorldEventsSelected([
            mapDataStore.currentWorldEventSlug,
        ]);
        GoogleAnalytics.sendTagsSelected(mapDataStore.tags);
    });
    autorun(function () {
        mapUpdater.assignFilters({
            "influencers[]": [mapDataStore.currentInfluenceSlug],
        });
        GoogleAnalytics.sendInfluencesSelected([mapDataStore.currentInfluenceSlug]);
        if (firstTimeListeningToCurrentInfluenceUpdates) {
            firstTimeListeningToCurrentInfluenceUpdates = false;
        }
        else {
            setUrlParam("current_influence", mapDataStore.currentInfluenceSlug);
        }
    });
};
export var setInitialMapDataStoreState = function () {
    var urlParams = fetchUrlParams();
    var startDate;
    var endDate;
    if (urlParams.get("start_date")) {
        startDate = new Date(urlParams.get("start_date"));
    }
    else {
        startDate = new Date(new Date().getFullYear(), 0, 1);
    }
    if (urlParams.get("end_date")) {
        endDate = new Date(urlParams.get("end_date"));
    }
    else {
        endDate = new Date();
    }
    mapDataStore.updateState({
        startDate: startDate,
        endDate: endDate,
        tags: urlParams.getAll("tags[]"),
        currentWorldEventSlug: urlParams.get("current_world_event"),
        currentInfluenceSlug: urlParams.get("current_influence"),
    });
};
