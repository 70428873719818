import { I18n } from "../i18n";

export const fetchTheme = async ({ uuid = "" } = {}) => {
  const response = await fetch(
    `/api/themes/${uuid}?locale=${I18n.currentLocale()}`
  );

  const response_json = await response.json();
  return response_json.data;
};
