import { I18n } from "../../i18n";

export class CollectionMarkAllItem {
  constructor($container) {
    this._$container = $container;
    this._$elem = null;
    this._$input = null;

    this._onSelectedCallback = (_) => {};
  }

  render() {
    this._$container.insertAdjacentHTML(
      "afterbegin",
      `<label class="panel-block option mark-all">
        <input class="" type="checkbox" name="" value="">
        ${I18n.t(`filter.mark_all`)}
      </label>`
    );

    this._$elem = this._$container.querySelector(".mark-all");
    this._$input = this._$elem.querySelector("input");

    this._bindSelectedEvent();
  }

  markChecked(flag) {
    this._$input.checked = flag;
  }

  displayAsActive() {
    this._$elem.classList.add("is-active");
  }

  displayAsNonActive() {
    this._$elem.classList.remove("is-active");
  }

  isSelected() {
    return this._$input.checked;
  }

  onSelected(callback) {
    this._onSelectedCallback = callback;
  }

  _bindSelectedEvent() {
    const self = this;
    this._$input.addEventListener("click", (event) => {
      if (self.isSelected()) {
        self.displayAsActive();
      } else {
        self.displayAsNonActive();
      }

      self._onSelectedCallback(event);
    });
  }
}
