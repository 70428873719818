var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { buildPopupHtml } from "./pin-popup";
import { fetchInfluenceLocations } from "../api/influence";
import { mapDataStore } from "../data-stores/map-data-store";
var MapUpdater = /** @class */ (function () {
    function MapUpdater(map) {
        this.map = map;
        this.currentMarkers = {};
        this.currentMarkersList = [];
        this.currentFilters = {};
    }
    MapUpdater.prototype.assignFilters = function (filters) {
        Object.assign(this.currentFilters, filters);
    };
    MapUpdater.prototype.removeFilter = function (key) {
        delete this.currentFilters[key];
    };
    MapUpdater.prototype.fetchFilter = function (key, defaultValue) {
        return this.currentFilters[key] || defaultValue;
    };
    MapUpdater.prototype.filterMap = function () {
        var self = this;
        if (!this.currentFilters["start_on"] || !this.currentFilters["end_on"]) {
            return;
        }
        fetchInfluenceLocations({ filters: this.currentFilters }).then(function (locations) {
            self.map.removeMarkers(self._currentMarkersList());
            self._cleanCurrentMarkers();
            locations.forEach(function (location) {
                self._pinInfluenceLocationOnMap(location);
            });
            // self.highlightMarkers(this.currentFilters["world_events[]"]);
        });
    };
    MapUpdater.prototype._openCurrentInfluence = function () {
        var currentMarkerData = this._currentMarkers()[mapDataStore.currentInfluenceSlug];
        if (mapDataStore.currentInfluenceSlug && currentMarkerData) {
            this.openPopup(mapDataStore.currentInfluenceSlug);
        }
    };
    MapUpdater.prototype.highlightMarkers = function (worldEventSlugs) {
        var markersToLower = [];
        this._currentMarkersList().forEach(function (marker) {
            var foundHighlightableWorldEvents = worldEventSlugs.filter(function (x) {
                return marker.worldEventSlugs.includes(x);
            });
            if (foundHighlightableWorldEvents.length <= 0) {
                markersToLower.push(marker);
            }
        });
        if (markersToLower.length < this._currentMarkersList().length) {
            markersToLower.forEach(function (marker) {
                marker.marker.setOpacity(0.4);
            });
        }
    };
    MapUpdater.prototype.isInfluenceOnMap = function (influenceSlug) {
        return !!this._currentMarkers()[influenceSlug];
    };
    MapUpdater.prototype.openPopup = function (influenceSlug) {
        var markerData = this._currentMarkers()[influenceSlug];
        if (markerData) {
            return markerData.marker.openPopup();
        }
    };
    MapUpdater.prototype._pinInfluenceLocationOnMap = function (location) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var influence, worldEvents, firstTag, popupHtml, popup, marker;
            var _this = this;
            return __generator(this, function (_b) {
                influence = location.attributes.influence.data;
                worldEvents = location.attributes.world_events.data;
                firstTag = location.attributes.tags.data[0];
                popupHtml = buildPopupHtml({
                    location: location,
                    influence: influence,
                    tags: location.attributes.tags.data,
                    startDate: this.currentFilters["start_on"],
                });
                popup = this.map.buildPopup({
                    content: popupHtml,
                    customClassName: "popup-influence-".concat(influence.id),
                });
                marker = this.map.addMarker({
                    lat: location.attributes.coords.lat,
                    lng: location.attributes.coords.lng,
                    popup: popup,
                    iconFilename: (_a = firstTag === null || firstTag === void 0 ? void 0 : firstTag.attributes) === null || _a === void 0 ? void 0 : _a.marker_image_filename,
                    onOpen: function (event) {
                        _this._bindPopupInternalActions(event.popup, location);
                        _this._setCurrentInfluenceDataStore(influence);
                    },
                    onClose: function (event) {
                        var closedInfluenceSlug = event.popup
                            .getElement()
                            .querySelector(".popup")
                            .getAttribute("data-influence-slug");
                        _this._removeCurrentInfluenceDataStore(closedInfluenceSlug);
                    },
                });
                marker.bindTooltip(influence.attributes.name);
                this._addCurrentMarker(marker, worldEvents, influence);
                this._openCurrentInfluence();
                return [2 /*return*/];
            });
        });
    };
    MapUpdater.prototype._bindPopupInternalActions = function (popup, influenceLocation) {
        var $titleLink = popup.getElement().querySelector(".title .link");
        var locationStartDate = new Date(influenceLocation.attributes.start_on);
        var locationEndDate = new Date(influenceLocation.attributes.end_on);
        $titleLink.addEventListener("click", function (event) {
            event.preventDefault();
            mapDataStore.updateState({
                startDate: locationStartDate,
                endDate: locationEndDate,
            });
        });
    };
    MapUpdater.prototype._setCurrentInfluenceDataStore = function (influence) {
        mapDataStore.updateState({
            currentInfluenceSlug: influence.attributes.slug,
        });
    };
    MapUpdater.prototype._removeCurrentInfluenceDataStore = function (influenceSlug) {
        if (mapDataStore.currentInfluenceSlug === influenceSlug) {
            mapDataStore.updateState({
                currentInfluenceSlug: "",
            });
        }
    };
    MapUpdater.prototype._currentMarkers = function () {
        return this.currentMarkers;
    };
    MapUpdater.prototype._currentMarkersList = function () {
        return this.currentMarkersList;
    };
    MapUpdater.prototype._cleanCurrentMarkers = function () {
        this.currentMarkers = {};
        this.currentMarkersList = [];
    };
    MapUpdater.prototype._addCurrentMarker = function (marker, worldEvents, influence) {
        var markerData = {
            marker: marker,
            worldEventSlugs: worldEvents.map(function (we) { return we.attributes.slug; }),
            influenceSlug: influence.attributes.slug,
        };
        this.currentMarkers[influence.attributes.slug] = markerData;
        this.currentMarkersList.push(markerData);
    };
    return MapUpdater;
}());
export { MapUpdater };
